import React from 'react'
import { graphql } from "gatsby"
import * as utils from '../utils'
import Layout from "../components/layout"
import SocialLinks from "../components/sociallinks"

export const pageQuery = graphql`
  query($slug: String!) {
    main: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        nid
        title
        cover_filepath
        image_filepath
        artists
        ituneslink
        spotifylink
        official
        date
        year
      }
      headings {
        value
        depth
      }
    }
  }
`

const ArtistListComponent = ({list, title}) => (
  list.length > 0 ?
  <div>
    <h4>{title}</h4>
    {list.map(t => {
      let img = utils.getImageURL(t.frontmatter.image_filepath || t.frontmatter.cover_filepath, 300)
      return img ? <div className="inlineartist">
        <a href={'/' + (t.fields && t.fields.slug)}>
          <img src={img} alt={t.frontmatter.title} />
          <span>{t.frontmatter.title}</span>
        </a>
      </div> : null
    })}
    <div className="clear"/>
  </div> : null
)

const TrackListComponent = ({list, title}) => (
  list.length > 0 ?
  <div>
    <table className="tracklist">
    {list.map(t => {
      return <tr>
        <td className="number">
        {t.frontmatter._tracknumber}
        </td>
        <td className="name">
          <b>
            {
              t.frontmatter.stream_filepath ?
                <a href={utils.getAudioURL(t.frontmatter.stream_filepath)}  >
                  {t.frontmatter.title}
                </a> :
                t.frontmatter.title
            }
          </b><br/>
          <i dangerouslySetInnerHTML={{ __html: t.html}}></i>
        </td>
        <td className="duration">
        {t.frontmatter._duration}
        </td>
        <td className="player">
        </td>
      </tr>
    })}
    </table>
  </div> : null
)

const findArtists = (all, list) => {
  const rel = (list || '').split(',')
  return (all || []).map(t => {
    t._match = rel.indexOf(t.frontmatter.nid.toString()) !== -1
    return t;
  }).filter(t => t._match)
}

export default (props) => {
  const {data} = props

  let allArtists = props.pageContext.allArtists.edges.map(t => t.node)
  let tracks = props.pageContext.allTracks.edges
    .map(t => t.node)
    .filter(t => t.frontmatter.album_id === data.main.frontmatter.nid)
    .sort((a, b) => {
      if (a.frontmatter._tracknumber < b.frontmatter._tracknumber) return -1;
      if (a.frontmatter._tracknumber > b.frontmatter._tracknumber) return 1;
      return 0;
    });
  let img = utils.getImageURL(data.main.frontmatter.cover_filepath || data.main.frontmatter.image_filepath, 1000)

  return <Layout>
    <div className="contentheader">
      <h1>
        {data.main.frontmatter.title}
        {' '}
        {data.main.frontmatter.year ? <em>({data.main.frontmatter.year})</em> : null}
      </h1>
      <SocialLinks frontmatter={data.main.frontmatter} />
    </div>
    <div className="trackswithcover">
      <div className="cover medium">
        {img ? <img src={img} className="medium" alt={data.main.frontmatter.title} /> : null}
      </div>
      <div className="tracks">
        <TrackListComponent title="Tracks" list={tracks} />
      </div>
    </div>
    <div className="clear"/>
    <hr className="hideonmobile" />
    <div className="mobilepadding">
      <div dangerouslySetInnerHTML={{ __html: data.main.html || ''}} />
      <div><ArtistListComponent title="Artists" list={findArtists(allArtists, data.main.frontmatter.artists)} /></div>
      <div><ArtistListComponent title="Featuring" list={findArtists(allArtists, data.main.frontmatter.supportingartists)} /></div>
    </div>
    {/* <pre>{JSON.stringify(data.main, null, 2)}</pre> */}
    {/* <pre>{JSON.stringify(tracks, null, 2)}</pre> */}
    {/* <pre>{JSON.stringify(allArtists, null, 2)}</pre> */}
  </Layout>
}
